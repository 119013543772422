<template>
	<div class="form-elements">
		<div class="container">
			<vuestic-widget>
				<div>
					<div class="row justify-content-center">
						<h2>{{ checklist.nome }}</h2>
					</div>
					<collapse
						v-for="area in checklist.area_da_checklist"
						:data="area"
						label="nome"
						:editar="false"
						:key="area.id"
					>
						<vuestic-widget class="p-0">
							<data-tables
								class="style-body-table"
								layout="table"
								:data="area.pergunta"
								:table-props="tableProps"
							>
								<el-table-column
									prop="pergunta"
									label="NOME"
									sortable="custom"
									width="200"
								/>
								<el-table-column
									prop="tipos_de_respostas"
									label="Resposta"
									sortable="custom"
								>
									<template slot-scope="row">
										<all
											:naoSeAplica="row.row.nao_se_aplica"
											:tipo="row.row.tipos_de_respostas"
										/>
									</template>
								</el-table-column>
							</data-tables>
						</vuestic-widget>
					</collapse>
				</div>
				<br />
				<div class="row d-flex justify-content-end">
					<button
						class="btn btn-pale btn-micro mr-2"
						@click="
							push('lista-checklist-duplicate-by-empresa-user-supervisor')
						"
					>
						Voltar
					</button>
					<button class="btn btn-primary btn-micro mr-2" @click="duplicate()">
						Duplicar
					</button>
				</div>
			</vuestic-widget>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import collapse from '@/components/my-components/form/collapse.vue';
import all from '@/vuestic-theme/vuestic-components/vuestic-tipo_de_resposta/todasAsRespostas.vue';

import notificationMixin from '@/utils/notification';

export default {
  mixins: [notificationMixin],
  name: 'cadastrar-checklist',
  components: {
    all,
    collapse,
  },
  props: {
    routerSuffix: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      show: false,
      tableProps: {
        stripe: true,
      },
      checklist: {},
      idChecklist: null,
    };
  },
  created() {
    this.init();
  },
  beforeRouteLeave(to, from, next) {
    this.$loading().close();
    next();
  },
  methods: {
    ...mapActions('checklist', ['getChecklistFullById', 'duplicateChecklist']),
    ...mapActions('acesso', ['postAcesso']),

    init() {
      this.idChecklist = this.$router.history.current.params.checklist;
      this.getChecklist();
    },
    openFullScreenLoading() {
      const loading = this.$loading({
        lock: true,
        text: 'Carregando',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      return loading;
    },
    async getChecklist() {
      let loading;
      try {
        loading = this.openFullScreenLoading();
        this.checklist = await this.getChecklistFullById({
          id: this.idChecklist,
          params: { 'sem-resposta': '' },
        });
      } catch (error) {
        console.error(error);
      } finally {
        loading.close();
      }
    },
    push(name, params) {
      this.$router.push({
        name: `${name}${this.routerSuffix}`,
        params,
      });
    },
    async duplicate() {
      try {
        await this.duplicateChecklist({
          ...this.checklist,
          empresa_id: Number(localStorage.empresaSelecionada),
        });
        this.message('success', 'Checklist Duplicada com sucesso!');
        this.push('lista-checklist-supervisor');
      } catch (error) {
        console.error(error);
        this.message('error', 'Erro na operação');
      }
    },
  },
  watch: {
    $route() {
      this.init();
    },
  },
};
</script>

<style>
.style-body-table {
	border: 1px solid #c2c2c2;
}
.cell .container .row .icons {
	width: 30px !important;
	height: 30px !important;
	margin: 0% !important;
}

.color-white {
	background: white;
}
</style>
